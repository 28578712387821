#ruvi-map {
    height: 100%;
    width: 100%;
    float: left;
    max-height: 100%;
    overflow: auto;
    overscroll-behavior: contain;
}

.ol-zoom {
    left: 10px;
    top: 60px;
    right: auto;
    bottom: auto;
}
.ol-control button:focus {
    color:none !important;
}

.ol-geolocation {
    left: 10px;
    top: 128px;
    right: auto;
    bottom: auto;
}
.ol-geolocation-on {
    color: #4C7C62 !important;
}
.ol-geolocation-follow {
    color: #FF6F49 !important;
}

.ol-round-1 {
    left: 10px;
    top: auto;
    right: auto;
    bottom: 10px;
}
.ol-round-2 {
    left: 40px;
    top: auto;
    right: auto;
    bottom: 10px;
}
.ol-round-3 {
    left: 70px;
    top: auto;
    right: auto;
    bottom: 10px;
}
.ol-round-on {
    color: #4C7C62 !important;
}
/*.ol-zoom button {
    /* margin: 0; *
    /* width: 30px; *
    /* height: 30px; *
    /* border-style: solid; *
    /* border-color: #d0006f; *
}
.ol-zoom .ol-zoom-in {
    /* border-radius: 0; *
    /* border-width: 1px 1px 1px 1px; *
}
.ol-zoom .ol-zoom-out {
    /* border-radius: 0; *
    /* border-width: 0px 1px 1px 1px; *
} */
.ol-control {
    background-color: transparent;
}
.ol-control:hover, .ol-control:focus {
    background-color: transparent;
}
.ol-control button {
    color: #F4F3F2;
    background-color: #D0006F;
}
.ol-control button:hover, .ol-control button:focus {
    color: #CEB888;
    background-color: #D0006F;
}
 