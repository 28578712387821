#ruvi-summary {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 15px;
    background-color: white;
    max-height: 100%;
    overflow: auto;
    overscroll-behavior: contain;
}

.row {
    text-align: left;
    margin-bottom: 10px;
}

