.App {
  width: 100%;
  height: 100vh;
  text-align: center;
  max-height: 100vh;
  overflow: auto;
  overscroll-behavior: contain;    

}

.dropdown-menu {
  padding:10px;
  border: 1px solid;
  border-radius: 5px;
  background-color: #004F71;
}

