#ruvi-start {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 15px;
    background-color: white;
    max-height: 100%;
    overflow: auto;
    overscroll-behavior: contain;
}

.row {
    text-align: left;
    margin-bottom: 10px;
}

/* .table tr th {
    white-space: nowrap;
} */

@media
only screen and (max-width: 500px) {
    #ruvi-start table, #ruvi-start thead, #ruvi-start tbody, #ruvi-start th, #ruvi-start td, #ruvi-start tr {
        display: block;
    }
    #ruvi-start thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }
    #ruvi-start tfoot tr {
        display: none;
    }
    #ruvi-start tr {
        border: 1px solid #343841;
        margin-bottom: 10px;
    }
    #ruvi-start td {
        border: none;
        border-bottom: 1px solid #EEEEEE;
        position: relative;
    }
    #ruvi-start td:before {
        position: absolute;
        white-space: nowrap;
    }
    #extraVisible {
    position: right;
    }
}
