#ruvi-form {
    position: fixed;
    top: 0;
    right: 0;
    width: 35%;
    min-width: 400px;
    height: 100%;
    background-color: white;
    padding: 15px;
    max-height: 100%;
    overflow: auto;
    overscroll-behavior: contain;
}

@media screen and (max-width: 400px) {
  #ruvi-form 
    {
      width: 100%;
      min-width: 100%;
  }
}

#ruvi-list {
  position: fixed;
  top: 15px;
  left: 15px;
  background-color: white;
}

.hidden {
    visibility: hidden;
}

#ruvi-form .dropdown-content div:hover {
  color:#CEB888;
  background-color: #343841;
}
